import { pluralize } from 'app/utils';

const getQuestions = (specialty: string) => {
  const pluralizedSpecialty =
    specialty &&
    specialty
      .split(' ')
      .map((word) => pluralize(word))
      .join(' ');
  return [
    {
      question: `Quais ${pluralizedSpecialty} aceitam meu convênio?`,
      answer: `Você pode buscar ${pluralizedSpecialty} que atendem seu convênio através da busca do AgendarConsulta, basta escolher seu convênio na barra de pesquisas e clique em pesquisar.`,
    },
    {
      question: `Como posso agendar um atendimento online com um ${specialty}`,
      answer: `Os ${pluralizedSpecialty} que possuem agendamento online apresentam a opção "agendar consulta" nas buscas.`,
    },
    {
      question: `Como posso agendar um atendimento com ${pluralizedSpecialty} que não possuem agendamento online.`,
      answer: `Você pode acessar o perfil do ${specialty} e entrar em contato por telefone, whatsapp ou enviar um e-mail para que ele possa agendar um atendimento.`,
    },
  ];
};

export default getQuestions;
