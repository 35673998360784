import { connect } from 'react-redux';
import Router from 'next/router';
import { handleSubmitLogin, LoginPayload } from 'app/state/auth/actions';
import { fetchUserLocation } from 'app/state/search/actions';

import SearchResult from '../components/SearchResult';

const mapDispatchToProps = (dispatch: any) => ({
  login: (params: LoginPayload) => dispatch(handleSubmitLogin(params)),
  getPageDoctors: (link: string) => {
    const url = new URL(link);
    const searchParams = new URLSearchParams(url.search);
    Router.push(`/buscar/${searchParams}`);
  },
  generateLink: (label: string, value: string) => {
    return `/buscar/${label}=${value}`;
  },
  getLocation: () => {
    const { geolocation } = navigator;
    if (!geolocation) return;
    geolocation.getCurrentPosition(({ coords }) => {
      const { latitude, longitude } = coords;
      dispatch(fetchUserLocation({ latitude, longitude }));
    });
  },
  navigateToProfile: (slug: string) =>
    Router.push(`/perfil/${slug}/?origin=profile_search`),
});

export default connect(
  ({ search }) => search,
  mapDispatchToProps,
)(SearchResult);
