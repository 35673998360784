import React from 'react';
import RowBoot from 'react-bootstrap/Row';

import css from './SearchResultTitle.module.scss';

type SearchResultTitleProps = {
  children: React.ReactNode;
};

function SearchResultTitle({ children }: SearchResultTitleProps) {
  return (
    <RowBoot className={css['search-result-title']}>
      <h1>{children}</h1>
    </RowBoot>
  );
}

export default SearchResultTitle;
