import React from 'react';
import ContainerBoot from 'react-bootstrap/Container';
import { H3, Text } from 'app/components';
import RowBoot from 'react-bootstrap/Row';
import ReactMarkdown from 'react-markdown';
import css from './SearchResultFAQ.module.scss';
import getQuestions from './SearchResultFAQ.questions';

type SearchResultFAQProps = {
  specialty: string;
  specializedContentText?: string;
};

function SearchResultFAQ({
  specialty,
  specializedContentText,
}: SearchResultFAQProps) {
  const faq = getQuestions(specialty);
  const questions = faq.map((item) => (
    <div
      key={item.question}
      className={css.box}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <div itemProp="name" className={css['box-question']}>
        {item.question}
      </div>
      <Text
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <div itemProp="text">{item.answer}</div>
      </Text>
    </div>
  ));

  return (
    <RowBoot className={css['search-result-faq']}>
      <ContainerBoot
        className={css.container}
        itemScope
        itemType="https://schema.org/FAQPage"
      >
        <H3>Perguntas Frequentes</H3>
        {questions}

        {specializedContentText && (
          <div className={css.box}>
            <ReactMarkdown className={css.markdown}>
              {specializedContentText}
            </ReactMarkdown>
          </div>
        )}
      </ContainerBoot>
    </RowBoot>
  );
}

SearchResultFAQ.defaultProps = {
  specializedContentText: '',
};

export default SearchResultFAQ;
