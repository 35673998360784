import React from 'react';
import Head from 'next/head';

type HeadSearchResultProps = {
  specialty?: string;
  insurance?: string;
  city?: string;
  optimized?: boolean;
};

function HeadSearchResult({
  specialty,
  insurance,
  city,
  optimized,
}: HeadSearchResultProps) {
  const insuranceText = insurance ? `o convênio ${insurance}` : null;
  const cityText = city ? `em ${city}` : null;

  const specialtyDefault = specialty || 'Especialistas';
  const cityDefault = city || 'sua cidade';

  const multiTitle = `Os 10 ${specialtyDefault} mais buscados em ${cityDefault}`;
  const simpleTitle = `${specialtyDefault} em ${cityDefault}`;
  const title = optimized ? multiTitle : simpleTitle;

  return (
    <Head>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content={`Veja a agenda dos principais médicos da sua região e agende sua consulta agora mesmo. ${
          specialty || 'Especialistas'
        } que atendem ${insuranceText || 'seu convênio'} ${
          cityText || 'na sua cidade'
        }.`}
      />
    </Head>
  );
}

HeadSearchResult.defaultProps = {
  specialty: null,
  insurance: null,
  city: null,
  optimized: false,
};

export default HeadSearchResult;
