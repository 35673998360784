import React from 'react';
import SearchResultTitle from './SearchResultTitle';

type TitleOptimizedProps = {
  specialty?: string;
  city?: string;
};

function TitleOptimized({ specialty, city }: TitleOptimizedProps) {
  const cityText = city ? ` em ${city}, ` : ', ';
  const specialtyText = specialty ? ` ${specialty.toLowerCase()} ` : null;

  return (
    <SearchResultTitle>
      <>
        Marque uma consulta com
        {specialtyText || ' especialistas '}
        mais buscados
        {cityText || ''}
        que atendem seu convênio
      </>
    </SearchResultTitle>
  );
}

TitleOptimized.defaultProps = {
  specialty: null,
  city: null,
};

export default TitleOptimized;
