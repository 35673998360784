/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { PureComponent } from 'react';
import RowBoot from 'react-bootstrap/Row';
import ColBoot from 'react-bootstrap/Col';
import ContainerBoot from 'react-bootstrap/Container';

import SearchFields from 'features/home/components/Search';
import imagePaths from 'shared/constants/imagePaths';
import { Button, Badge } from 'app/components';
import {
  insuranceTelemedicineSelect,
  userHasOnlinePayment,
} from 'app/state/profile/utils';
import icons from 'app/shared/paths/icons';
import { captalize, captalizeWords } from '../../../utils';
import css from './SearchList.module.scss';
import { DataSearch, Doctor, DoctorResponse } from '../types';

import SearchResultTitle from './SearchResultTitle';
import TitleOptimized from './TitleOptimized';

type SearchListProps = DataSearch & {
  navigateToProfile: (slug: string) => void;
  getPageDoctors: (link: string) => void;
  userLocation: string;
  getLocation: () => void;
  optimized: boolean;
  doctors: DoctorResponse;
};

class Search extends PureComponent<SearchListProps, {}> {
  componentDidMount() {
    const { getLocation } = this.props;
    getLocation();
  }

  renderList = (list: Doctor[] = []) => {
    const { navigateToProfile } = this.props;
    return list.map(
      ({
        picture,
        full_name,
        cbo,
        addresses,
        slug,
        has_scheduling,
        insurances,
        id,
      }) => (
        <RowBoot
          itemType="https://schema.org/Physician"
          itemScope
          key={slug}
          className={css.row}
        >
          <ColBoot className={css['image-content']} lg={2} md={3} sm={3} xs={5}>
            <img
              itemProp="image"
              alt="foto"
              src={picture || '/static/images/avatar.jpg'}
              onClick={() => navigateToProfile(slug)}
            />
          </ColBoot>
          <ColBoot
            className={css['description-content']}
            lg={6}
            md={5}
            sm={9}
            xs={7}
          >
            <h3
              itemProp="name"
              className={css.physician}
              onClick={() => navigateToProfile(slug)}
            >
              {full_name}
            </h3>
            <p
              itemProp="medicalSpecialty"
              onClick={() => navigateToProfile(slug)}
              className={css.subTitle}
            >
              {cbo.term}
            </p>
            <div
              itemScope
              itemProp="address"
              itemType="https://schema.org/PostalAddress"
              className={css['address-content']}
            >
              {addresses && addresses.length > 0 && (
                <>
                  <p itemProp="streetAddress">{`${addresses[0].address}, ${addresses[0].number}`}</p>
                  <p itemProp="postalCode">{addresses[0].zipcode}</p>
                  <p>
                    <span itemProp="addressLocality">{addresses[0].city}</span>
                    {' - '}
                    <span itemProp="addressRegion">{addresses[0].state}</span>
                  </p>
                </>
              )}
            </div>
            {insuranceTelemedicineSelect(insurances).length > 0 && (
              <Badge
                icon={icons.videocam}
                color="blue"
                text="Atende teleconsulta"
              />
            )}
            {userHasOnlinePayment(id) && (
              <Badge icon={icons.money} color="blue" text="Pagamento Online" />
            )}
          </ColBoot>
          <ColBoot className={css['button-container']} lg={4} md={12} sm={12}>
            <Button
              onClick={() => navigateToProfile(slug)}
              block
              className={css['button-custom']}
              kind={has_scheduling ? 'info' : 'default'}
            >
              {has_scheduling ? 'Agendar consulta' : 'Ver perfil do médico'}
            </Button>
          </ColBoot>
        </RowBoot>
      ),
    );
  };

  renderTitle = () => {
    const { autocomplete, city, insurance } = this.props;
    let title = autocomplete ? captalize(autocomplete) : 'Médicos';
    title += city ? ` em ${captalizeWords(city)}` : '';
    title += insurance ? ` - ${captalizeWords(insurance)}` : '';

    return <SearchResultTitle>{title}</SearchResultTitle>;
  };

  render() {
    const {
      autocomplete,
      city,
      insurance,
      accepts_in_person: acceptsInPerson,
      userLocation,
      getPageDoctors,
      doctors: {
        data = [],
        count = 0,
        links = { next: '', previous: '' },
        total_pages = 0,
      },
      specialty,
      optimized,
    } = this.props;

    const { previous, next } = links;

    return (
      <div className={css['search-list-container']}>
        <SearchFields
          searching
          cityValue={city}
          insuranceValue={insurance}
          userLocation={userLocation}
          autocompleteValue={autocomplete}
          acceptsInPersonValue={acceptsInPerson}
          acceptsTelemedicineValue={!acceptsInPerson}
        />
        <RowBoot className={css['search-list']}>
          <ColBoot lg={12} md={12} sm={12}>
            <ContainerBoot className={css.container}>
              {data.length > 0 ? (
                <div className={css['result-content']}>
                  {optimized ? (
                    <TitleOptimized
                      specialty={specialty}
                      city={city}
                      insurance={insurance}
                    />
                  ) : (
                    this.renderTitle()
                  )}
                  {this.renderList(data)}
                  <RowBoot>
                    <div className={css.pagination}>
                      <Button
                        onClick={() => getPageDoctors(previous)}
                        disabled={!previous}
                        variant="light"
                        size="sm"
                      >
                        <img alt="flecha esquerda" src={imagePaths.arrowLeft} />
                      </Button>
                      <Button
                        onClick={() => getPageDoctors(next)}
                        disabled={!next}
                        variant="light"
                      >
                        <img alt="flecha direita" src={imagePaths.arrowRight} />
                      </Button>
                      <p>{`Exibindo ${count} de ${total_pages} páginas`}</p>
                    </div>
                  </RowBoot>
                </div>
              ) : (
                <div className={css['empty-content']}>
                  <img alt="busca" src={imagePaths.fill} />
                  <p className={css.bold}>Nenhum profissional encontrado.</p>
                  <p>
                    Mude os parâmetros da sua busca para encontrar
                    profissionais.
                  </p>
                </div>
              )}
            </ContainerBoot>
          </ColBoot>
        </RowBoot>
      </div>
    );
  }
}

export default Search;
