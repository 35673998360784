// External
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

// Internal
import information from 'app/shared/constants/information';
import { getLinks } from 'app/services/api/search';
import Links from 'features/home/components/Links';
import { fetchResultSearch } from 'app/state/search/actions';
import LayoutBootstrap from '../../layout-bootstrap/LayoutBootstrap';
import SearchList from './SearchList';
import HeadSearchResult from './HeadSearchResult';
import { pluralize } from '../../../utils';
import { DoctorResponse } from '../types';
import SearchResultFAQ from './SearchResultFAQ';

type SearchResultProps = {
  navigateToProfile: (slug: string) => void;
  getPageDoctors: (link: string) => void;
  userLocation: string;
  getLocation: () => void;
  generateLink: (label: string, value: string | boolean) => string;
  doctors: DoctorResponse;
  login: (e: {}) => void;
};

const SearchResult = ({
  navigateToProfile,
  getPageDoctors,
  userLocation,
  getLocation,
  generateLink,
  doctors,
  login,
}: SearchResultProps) => {
  const router = useRouter();
  const { search } = router.query;
  const {
    optimized,
    autocomplete,
    city,
    insurance,
    accepts_in_person: acceptsInPerson,
    specializedContentText,
  } = Object.fromEntries(new URLSearchParams(search as string).entries());

  const specialty =
    autocomplete &&
    autocomplete
      .split(' ')
      .map((word) => pluralize(word))
      .join(' ');

  const [links, setLinks] = useState(information);
  useEffect(() => {
    (async () => {
      const { data: responseLinks } = await getLinks();
      setLinks(responseLinks);
    })();
  }, []);

  return (
    <>
      <LayoutBootstrap login={login}>
        <HeadSearchResult
          specialty={specialty}
          insurance={insurance}
          city={city}
          optimized={!!optimized}
        />
        <SearchList
          autocomplete={autocomplete}
          city={city}
          specialty={specialty}
          insurance={insurance}
          accepts_in_person={!(acceptsInPerson === 'false')}
          doctors={doctors}
          getLocation={getLocation}
          userLocation={userLocation}
          getPageDoctors={getPageDoctors}
          navigateToProfile={navigateToProfile}
          optimized={!!optimized}
        />
        {optimized && (
          <SearchResultFAQ
            specialty={autocomplete}
            specializedContentText={specializedContentText}
          />
        )}
        <Links generateLink={generateLink} information={links} />
      </LayoutBootstrap>
    </>
  );
};

SearchResult.getInitialProps = async ({ store, query, isServer }: any) => {
  const { search } = query;
  const {
    autocomplete,
    city,
    insurance,
    accepts_in_person,
    accepts_telemedicine,
    page,
  } = Object.fromEntries(new URLSearchParams(search).entries());

  await store.dispatch(
    fetchResultSearch({
      autocomplete,
      city,
      insurance,
      accepts_in_person,
      accepts_telemedicine,
      page,
    }),
  );
  return { isServer };
};

export default SearchResult;
